import { useEffect, useState } from "react";
import { $, $$ } from "./rk/utility";
import { Magnetic } from "./rk/magnetic";
import { ParticleSystem } from "./rk/particle";
import { getVerbs } from "./verbs";
import "./App.css";
import "./rk/particle.css";

function App() {
    const [state, setState] = useState(0);
    const [currentNum, setCurrentNum] = useState(1);
    const [currentVerb, setCurrentVerb] = useState([]);
    const [shownVerb, setShownVerb] = useState(0);
    const [fieldClass, setFieldClass] = useState(["", "", ""]);
    const [checkButtonText, setCheckButtonText] = useState("Check");
    const all = 10;
    let verbs = getVerbs();
    const [good, setGood] = useState(0);
    const [particleSystem, setParticleSystem] = useState(null);

    useEffect(() => {
        const app = $(".App");

        let tempParticleSystem = new ParticleSystem(
            app,
            ["😎", "👍", "😊"],
            20,
            0,
            1000,
            15,
            60
        );
        setParticleSystem(tempParticleSystem);
        tempParticleSystem.start();
    }, []);

    useEffect(() => {
        const button = $$("[magnetic]");
        button.forEach((button) => new Magnetic(button, 10, 0.5));
    }, [state]);

    const onStartClick = () => {
        particleSystem.stop();
        setState(1);
        generateVerb();
    };

    const generateVerb = () => {
        let verb = verbs[Math.floor(Math.random() * verbs.length)];
        setCurrentVerb(verb);
        setShownVerb(Math.floor(Math.random() * 3));
    };

    const checkAnswer = () => {
        if (checkButtonText === "Check") {
            let tempGood = 0;

            let answer0 = "";
            let answer1 = "";
            let answer2 = "";

            if (shownVerb === 0) {
                answer0 = currentVerb[0];
                answer1 = $("#i1").value;
                answer2 = $("#i2").value;
            } else if (shownVerb === 1) {
                answer0 = $("#i0").value;
                answer1 = currentVerb[1];
                answer2 = $("#i2").value;
            } else if (shownVerb === 2) {
                answer0 = $("#i0").value;
                answer1 = $("#i1").value;
                answer2 = currentVerb[2];
            }

            let newFieldClass = ["", "", ""];

            console.log(`${answer0} ${answer1} ${answer2}`);

            if (
                answer0.toString().toLowerCase() ==
                currentVerb[0].toString().toLowerCase()
            ) {
                newFieldClass[0] = "correct";
                if (shownVerb !== 0) {
                    tempGood++;
                }
            } else {
                newFieldClass[0] = "incorrect";
            }

            if (
                answer1.toString().toLowerCase() ==
                currentVerb[1].toString().toLowerCase()
            ) {
                newFieldClass[1] = "correct";
                if (shownVerb !== 1) {
                    tempGood++;
                }
            } else {
                newFieldClass[1] = "incorrect";
            }

            if (
                answer2.toString().toLowerCase() ==
                currentVerb[2].toString().toLowerCase()
            ) {
                newFieldClass[2] = "correct";
                if (shownVerb !== 2) {
                    tempGood++;
                }
            } else {
                newFieldClass[2] = "incorrect";
            }

            setGood(good + tempGood);

            setFieldClass(newFieldClass);

            if (currentNum < all) {
                setCheckButtonText("Next");
            } else {
                setCheckButtonText("Finish");
            }
        } else if (checkButtonText === "Next") {
            generateVerb();
            setCheckButtonText("Check");
            setFieldClass(["", "", ""]);
            setCurrentNum(currentNum + 1);

            if ($("#i0") != null) {
                $("#i0").value = "";
            }

            if ($("#i1") != null) {
                $("#i1").value = "";
            }

            if ($("#i2") != null) {
                $("#i2").value = "";
            }
        } else if (checkButtonText === "Finish") {
            setState(2);
            setCheckButtonText("Try again");
        } else if (checkButtonText === "Try again") {
            setState(0);
            setCurrentNum(1);
            setGood(0);
            setCheckButtonText("Check");
            setFieldClass(["", "", ""]);
            particleSystem.start();
        }
    };

    return (
        <div className="App">
            {state === 0 ? (
                <main>
                    <img
                        src="favicon.ico"
                        style={{ width: "65px", marginBottom: "20px" }}
                    ></img>
                    <h2>PRACTICE IRREGULAR VERBS</h2>
                    <h4 className="credit">by Rudolf Korcsmaros</h4>
                    <div id="space100"></div>
                    <button id="startButton" magnetic="" onClick={onStartClick}>
                        Start
                    </button>
                </main>
            ) : state === 1 ? (
                <main>
                    <div id="container">
                        <div className={`field ${fieldClass[0]}`}>
                            {shownVerb === 0 ||
                            fieldClass[0] === "incorrect" ? (
                                <p>{currentVerb[0]}</p>
                            ) : (
                                <input name="0" id="i0" autoComplete="off" />
                            )}
                        </div>
                        <div className={`field ${fieldClass[1]}`}>
                            {shownVerb === 1 ||
                            fieldClass[1] === "incorrect" ? (
                                <p>{currentVerb[1]}</p>
                            ) : (
                                <input name="1" id="i1" autoComplete="off" />
                            )}
                        </div>
                        <div className={`field ${fieldClass[2]}`}>
                            {shownVerb === 2 ||
                            fieldClass[2] === "incorrect" ? (
                                <p>{currentVerb[2]}</p>
                            ) : (
                                <input name="2" id="i2" autoComplete="off" />
                            )}
                        </div>
                        <button id="check" onClick={checkAnswer}>
                            {checkButtonText}
                        </button>
                        <div className="current">
                            {currentNum} / {all}
                        </div>
                    </div>
                </main>
            ) : (
                <main>
                    <h3
                        style={{ marginBottom: "20px" }}
                    >{`Your score is: ${Math.floor(
                        (good / (all * 3)) * 100
                    )}%`}</h3>
                    <button id="check" magnetic="" onClick={checkAnswer}>
                        {checkButtonText}
                    </button>
                </main>
            )}
            <footer>
                <p>MADE BY KRUDI ♥</p>
            </footer>
        </div>
    );
}

export default App;
