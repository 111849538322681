import gsap from "gsap";
import { clamp } from "./utility";

export class Magnetic {
    constructor(el, range = 10, duration = 0.5) {
        this.el = el;
        this.range = range;
        this.duration = duration;
        this.init();
    }

    init() {
        this.rectOrigin = this.el.getBoundingClientRect();

        this.el.addEventListener("mousemove", this.mousemove.bind(this));
        this.el.addEventListener("mouseout", this.mouseout.bind(this));
    }

    mousemove(e) {
        let x = e.clientX - this.rectOrigin.left;
        let y = e.clientY - this.rectOrigin.top;

        x -= e.target.offsetWidth / 2;
        y -= e.target.offsetHeight / 2;

        gsap.to(this.el, {
            x: clamp(x / 2, -this.range, this.range),
            y: clamp(y / 2, -this.range, this.range),
            duration: this.duration,
        });
    }

    mouseout() {
        gsap.killTweensOf(this.el);
        gsap.to(this.el, {
            x: 0,
            y: 0,
            duration: this.duration,
        });
    }
}
