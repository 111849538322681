import gsap from "gsap";

class Particle {
    constructor(
        container,
        text,
        x,
        y,
        size,
        distanceX,
        distanceY,
        duration,
        lifetime
    ) {
        this.container = container;
        this.text = text;
        this.x = x;
        this.y = y;
        this.size = size;
        this.distanceX = distanceX;
        this.distanceY = distanceY;
        this.duration = duration;
        this.lifetime = lifetime;
        this.init();
    }

    init() {
        this.el = this.container.appendChild(this.createInstance());

        gsap.to(this.el, {
            x: this.distanceX,
            y: this.distanceY,
            duration: this.duration,
            ease: "none",
        });

        setTimeout(() => {
            this.el.style.opacity = 0;
            setTimeout(() => {
                this.container.removeChild(this.el);
            }, 1000);
        }, this.lifetime * 1000 - 500);

        this.el.addEventListener("mouseover", this.mouseover.bind(this));
        this.el.addEventListener("mouseout", this.mouseout.bind(this));
    }

    createInstance() {
        let instance = document.createElement("div");

        instance.classList.add("particle");
        instance.innerHTML = this.text;
        instance.style.fontSize = this.size + "px";
        instance.style.left = this.x + "px";
        instance.style.top = this.y + "px";

        return instance;
    }

    mouseover() {
        this.el.style.fontSize = this.size * 1.5 + "px";
        this.el.style.left = this.x - this.size * 0.25 + "px";
    }

    mouseout() {
        this.el.style.fontSize = this.size + "px";
        this.el.style.left = this.x + "px";
    }
}

export class ParticleSystem {
    constructor(container, text, size, distanceX, distanceY, lifetime, number) {
        this.container = container;
        this.text = text;
        this.size = size;
        this.distanceX = distanceX;
        this.distanceY = distanceY;
        this.lifetime = lifetime;
        this.number = number;
        this.init();
    }

    init() {
        this.delay = this.lifetime / (this.number * (window.innerWidth / 1920));
    }

    summonParticle() {
        let x = Math.random() * (window.innerWidth - 100) + 50;
        let y = -50;

        new Particle(
            this.container,
            this.text[(Math.random() * this.text.length) | 0],
            x,
            y,
            this.size,
            this.distanceX,
            this.distanceY,
            15,
            this.lifetime * (window.innerHeight / 1080)
        );
    }

    start() {
        this.interval = setInterval(
            this.summonParticle.bind(this),
            this.delay * 1000
        );
    }

    stop() {
        clearInterval(this.interval);
        this.interval = null;
    }

    currentStatus() {
        return this.interval ? 1 : 0;
    }
}
