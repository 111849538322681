let verbs = `be
was/were
been
become 
became
become
begin
began
begun
bet
bet
bet
bite
bit
bitten
blow
blew
blown
break
broke
broken
bring
brought
brought
build
built
built
buy
bought
bought
catch
caught
caught
choose
chose
chosen
come
came
come
cost
cost
cost
creep
crept
crept
cut
cut
cut
deal
dealt
dealt
dig
dug
dug
do
did
done
draw
drew
drawn
drink
drank
drunk
drive
drove
driven
eat
ate
eaten
fall
fell
fallen
feed
fed
fed
feel
felt
felt
fight
fought
fought
find
found
found
flee
fled
fled
fly
flew
flown
forbid
forbade
forbidden
forget
forgot
forgotten
forgive
forgave
forgiven
freeze
froze
frozen
get
got
got
give
gave
given
go
went
gone
grow
grew
grown
hang
hung
hung
have
had
had
hear
heard
heard
hide
hid
hidden
hit
hit
hit
hold
held
held
hurt
hurt
hurt
keep
kept
kept
kneel
knelt
knelt
know
knew
known
lay
laid
laid
lead
led
led
leave
left
left
lend
lent
lent
let
let
let
lie
lay
lain
light
lit
lit
lose
lost
lost
make
made
made
mean
meant
meant
meet
met
met
pay
paid
paid
put
put
put
read
read
read
ride
rode
ridden
ring
rang
rung
rise
rose
risen
run
ran
run
say
said
said
see
saw
seen
seek
sought
sought
sell
sold
sold
send
sent
sent
set
set
set
sew
sewed
sewn/sewed
shake
shook
shaken
shine
shone
shone
shoot
shot
shot
show
showed
shown/showed
shrink
shrank
shrunk
shut
shut
shut
sing
sang
sung
sink
sank
sunk
sit
sat
sat
sleep
slept
slept
slide
slid
slid
speak
spoke
spoken
spend
spent
spent
spit
spat
spat
split
split
split
spread
spread
spread
stand
stood
stood
steal
stole
stolen
sting
stung
stung
stink
stank
stunk
strike
struck
struck
swear
swore
sworn
sweep
swept
swept
swim
swam
swum
take 
took
taken
teach
taught
taught
tear
tore
torn
tell
told
told
think
thought
thought
throw
threw
thrown
understand
understood
understood
wake
woke
woken
wear
wore
worn
weep
wept
wept
win
won
won
write
wrote
written
learn
learnt
learnt
hear
heard
heard
burn
burnt
burnt
`;

function arrayFromText(text) {
    var array = text.split("\n");
    var result = [];
    for (var i = 0; i < array.length; i++) {
        result.push(array[i].split(" "));
    }
    return result;
}

function divideArrayToPiecesOfThree(array) {
    var result = [];
    for (var i = 0; i < array.length; i += 3) {
        result.push(array.slice(i, i + 3));
    }
    return result;
}

let verbsArray = divideArrayToPiecesOfThree(arrayFromText(verbs));

export function getVerbs() {
    return verbsArray;
}
